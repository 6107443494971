<template>
	<b-container class="main-home">
		<div class="main-section-wrapper" data-aos="home-animation">
			<div class="image-wrapper">
				<img v-if="windowWidth < 992" src="../assets/images/home-main-pic-mobile.jpg" class="w-100 p-0 img-fluid" />
				<img v-else src="../assets/images/home-main-pic-desktop.jpg" class="w-100 p-0 img-fluid" />

				<h1 class="image-over piece-1 slide-right">"Die passende anlage</h1>
				<h1 class="image-over piece-2 slide-left">zum greifen nahe..."</h1>
				<div class="content-wrapper"></div>
			</div>
		</div>
		<b-row>
			<div class="title-wrapper xl-title">
				<h1>{{ $t('home.first-section.title') }}</h1>
			</div>
		</b-row>
		<b-container class="concepts home">
			<VueSlickCarousel :slidesToShow="3" :responsive="responsive" :arrows="true" :draggable="false">
				<div>
					<img src="../assets/images/slider_3.jpg" class="w-100 p-2 img-fluid" />
					<div class="title-wrapper homeslider-title-small"><h2>Objekt</h2></div>
				</div>
				<div>
					<img src="../assets/images/home_slider2.jpg" class="w-100 p-2 img-fluid" />
					<div class="title-wrapper homeslider-title-medium"><h2>Finanzierung</h2></div>
				</div>
				<div>
					<img src="../assets/images/home_slider3.jpg" class="w-100 p-2 img-fluid" />
					<div class="title-wrapper homeslider-title-medium"><h2>Verwaltung</h2></div>
				</div>
			</VueSlickCarousel>

			<div class="subtitle-wrapper">
				<h3 class="strong-partner">Alles mit nur einem starken Partner</h3>
			</div>

			<div class="text-wrapper my-3">
				<p class="under-partner">
					Unser Immobilien Konzept ermöglicht Ihnen einen einfachen Einstieg in das Thema Immobilie als Kapitalanlage. Unser Konzept entstand aus der Idee unsere Kunden voll und ganz
					abzusichern wenn es darum geht eine Immobilie als Kapitalanlage zu erwerben.
				</p>
				<p class="under-partner">
					Wir haben es geschafft den Faktor Zeit und Aufwand für unsere Kunden auf ein Minimum zu reduzieren. Lehnen Sie sich zurück und überlassen Sie uns die Arbeit.
				</p>
			</div>

			<div class="mt-5">
				<b-row class="animation-wrapper mb-3">
					<b-col sm="12" md="6" class="animate-colored-wrap">
						<img src="../assets/images/house_colored.png" />
						<div class="ball ball-1" :class="{ 'is-active': hovering === 1 }" @mouseover="handleSelected(1, 'ball-one')"><h5>Objekt</h5></div>
						<div class="ball ball-2" :class="{ 'is-active': hovering === 2 }" @mouseover="handleSelected(2, 'ball-two')"><h5>Verwaltung</h5></div>
						<div class="ball ball-3" :class="{ 'is-active': hovering === 3 }" @mouseover="handleSelected(3, 'ball-three')"><h5>Finanzierung</h5></div>
						<div class="ball ball-4" :class="{ 'is-active': hovering === 4 }" @mouseover="handleSelected(4, 'ball-four')"><h5>Steuervorteile</h5></div>
						<div class="ball ball-5" :class="{ 'is-active': hovering === 5 }" @mouseover="handleSelected(5, 'ball-five')"><h5>Versicherung</h5></div>
					</b-col>
					<b-col sm="12" md="6">
						<div v-for="content in content" :key="content">
							<div v-if="hovering === content" class="text-wrapper">
								<div class="title-wrapper medium-title">
									<h3>{{ ballContent[`${selected}`].title }}</h3>
								</div>
								<p :class="{ 'open-text': showText }">
									{{ ballContent[`${selected}`].text }}
								</p>
								<div v-if="windowWidth < 991">
									<b-row class="button-row">
										<div class="button-wrapper">
											<router-link to="/contact">
												<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
											</router-link>
										</div>
									</b-row>
								</div>
							</div>
						</div>
					</b-col>
				</b-row>
				<b-row class="button-row " style="margin-top:100px" v-if="windowWidth > 992">
					<div class="button-wrapper">
						<router-link to="/contact">
							<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
						</router-link>
					</div>
				</b-row>
			</div>
		</b-container>

		<div class="second-section-wrapper">
			<b-row>
				<div class="title-wrapper xl-title">
					<h1>{{ $t('home.second-section.title') }}</h1>
				</div>
			</b-row>
			<b-row class="row-text-wrap" style="padding:0 50x;">
				<b-col sm="12" md="6" class="left-text">
					<p>{{ $t('home.second-section.text-one') }}</p>
				</b-col>
				<b-col sm="12" md="6" class="right-text">
					<p>{{ $t('home.second-section.text-two') }}</p>
				</b-col>
			</b-row>
			<b-row class="button-row">
				<router-link to="/anlage" class="animation">
					<b-button class="btn btn-blue btn-medium-big">{{ $t('home.second-section.button-text') }}</b-button>
				</router-link>
			</b-row>
		</div>

		<img src="../assets/images/home-between.jpg" class="w-100 img-fluid" />

		<div class="third-section-wrapper">
			<b-row>
				<div class="title-wrapper xl-title reasons">
					<h1 style="textAlign: center">{{ $t('home.third-section.title') }}</h1>
				</div>

				<p style="textAlign: center" class="subtitle-list">{{ $t('home.third-section.subtitle') }}</p>

				<b-col sm="12" md="6" class="col-left">
					<ul>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_1') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_2') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_3') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_4') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_5') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_6') }}
							</p>
						</li>
					</ul>
				</b-col>
				<b-col sm="12" md="6" class="col-right">
					<ul>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_7') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_8') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_9') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_10') }}
							</p>
						</li>
						<li>
							<p>
								<b-icon-key />
								{{ $t('home.third-section.list.list_elem_11') }}
							</p>
						</li>
					</ul>
				</b-col>
			</b-row>
			<b-row class="button-row">
				<div class="button-wrapper button-left">
					<router-link to="/anlage">
						<b-button class="btn btn-blue btn-big">{{ $t('home.second-section.button-text') }}</b-button>
					</router-link>
				</div>
				<div class="button-wrapper button-right">
					<router-link to="/contact">
						<b-button class="btn btn-blue btn-big">{{ $t('leistungen.button') }}</b-button>
					</router-link>
				</div>
			</b-row>
		</div>
	</b-container>
</template>

<script>
	import VueSlickCarousel from 'vue-slick-carousel';
	import 'vue-slick-carousel/dist/vue-slick-carousel.css';
	// optional style for arrows & dots
	import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css';
	export default {
		name: 'Home',
		components: { VueSlickCarousel },
		data: () => ({
			windowWidth: window.innerWidth,
			listitems: [],
			content: [1, 2, 3, 4, 5],
			hovering: 1,
			showText: true,
			selected: 'ball-one',
			ballContent: {
				'ball-one': {
					title: 'Das passende Objekt',
					text:
						'Am Anfang Ihrer Investition muss erstmal ein passendes Objekt stehen. Eine Wohnung oder ein Haus, das zu Ihnen, Ihren Wünschen, Ihren Zielen und Ihrer Bonität passt. Wir haben deutschlandweit attraktive Objekte für Sie zur Auswahl und finden immer die passende Anlageimmobilie. Ganz wichtig: Wir vermitteln exklusiv & Off-Market. Eine Immobilie über unsere Firmengruppe ist daher immer ein Unikat. Darauf sind wir besonders stolz und freuen uns Ihnen die Türen in die Welt der Immobilien mit einer breiten Auswahl an exklusiven Angeboten öffnen zu können. Schaffen Sie sich ein starkes Fundament mit der richtigen Kapitalanlage - Immobilien.',
				},

				'ball-two': {
					title: 'Die stressfreie Verwaltung',
					text:
						'Mit dem Kauf einer Immobilie ist es meist nicht getan – Objektpflege, Abrechnung und Betreuung der Mieter bringen Aufgaben mit sich. Denn wenn Ihre Kapitalanlage nicht bei Ihnen in der Nähe liegt, wollen Sie sicher nicht bei jedem Anlass hunderte von Kilometern fahren, um sich darum zu kümmern. Gut, wenn Sie sich dann auf einen starken Partner verlassen können. Wir bieten Ihnen die Möglichkeit, die volle Kontrolle zu behalten. Wir arbeiten mit ausgesuchten Hausverwaltungen zusammen, die sich um alles kümmern. Objektpflege, Abrechnung und Mieterbetreuung stehen somit nicht mehr auf Ihrer To-Do-Liste. Nutzen Sie diesen stressfreien Service und lassen Sie andere die Verwaltungsarbeit übernehmen.',
				},
				'ball-three': {
					title: 'Die optimale Finanzierung',
					text:
						'Eines unserer Objekte hat Ihnen zugesagt und Sie wollen konkret werden. Dann brauchen Sie als nächstes eine Bank, welche Ihnen eine gute Finanzierung auf die Beine stellt. Gerade in heutiger Zeit lohnt es sich mehrere Angebote zu vergleichen. Gerne können Sie mit Ihren Bankkontakten sprechen oder wir empfehlen Ihnen einen unserer unabhängigen Finanzierungsexperten. Mit diesen haben wir bisher nur gute Erfahrungen gemacht. Die Finanzierungsexperten arbeiten im Regelfall mit mehreren Banken zusammen und entscheiden unparteiisch. Egal wer Sie begleitet, das Ziel ist: Bestmögliche Konditionen für Ihre Finanzierung als optimalen Fremdkapitalhebel.',
				},
				'ball-four': {
					title: 'Die vielen Steuervorteile',
					text:
						'Wenn Sie in Immobilien als Kapitalanlage investieren, gibt es viele steuerliche Aspekte, die Sie beachten sollten. Darunter auch einige Vorteile, die es zu nutzen gilt. Welche das sind und wie Sie davon profitieren erklärt Ihnen Ihr Steuerberater. Falls Sie keinen haben, empfehlen wir Ihnen gerne kompetente Kanzleien. Sprechen Sie uns einfach an, wir stellen den Kontakt für Sie her.',
				},
				'ball-five': {
					title: 'Die richtige Absicherung',
					text:
						'Die Risiken bei einer Immobilie als Kapitalanlage sind relativ überschaubar – und lassen sich weitgehend minimieren. Ein wichtiges Thema: Mietausfall und Mietnomaden. Das ist nicht nur ärgerlich, sondern kann auch ernste Folgen haben. Unser Ratschlag: Versichern Sie sich gegen diese Risiken. In Zusammenarbeit mit einer namenhaften Versicherungsgesellschaft können wir Ihnen diese Absicherung anbieten, und Sie sind diese Sorgen los. Fragen Sie uns, wir informieren Sie gerne über die Möglichkeit, sich gegen Mietausfall und Mietnomaden abzusichern.',
				},
			},
			responsive: [
				{
					breakpoint: 1024,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1,
						draggable: true,
					},
				},
				{
					breakpoint: 576,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		}),
		methods: {
			handleSelected(ballNum, ball) {
				this.hovering = ballNum;
				this.showText = false;
				this.selected = ball;
				setTimeout(() => (this.showText = true), 50);
			},
		},
		mounted() {
			window.onresize = () => {
				this.windowWidth = window.innerWidth;
			};
		},
	};
</script>
