<template>
	<div id="app" :class="{ darkenCookies: showCookies }">
		<b-navbar class="navbar w-100" toggleable="lg" sticky-top :class="{ change_color: scrollPosition > 50 }">
			<div class="nav-wrapper">
				<div class="navbar-brand">
					<router-link to="/">
						<img v-if="scrollPosition < 50" :src="logo" height="80" />
						<img v-else :src="logoOnly" height="50" class="mr-3" />
					</router-link>
				</div>
				<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
				<b-collapse id="nav-collapse" is-nav>
					<b-navbar-nav>
						<b-nav-item v-if="windowWidth < 992">
							<router-link to="/" class="animation">
								<span>Home</span>
							</router-link>
						</b-nav-item>
						<b-nav-item>
							<router-link to="/anlage" class="animation">
								<span>{{ $t('home.navigation.links.first') }}</span>
							</router-link>
						</b-nav-item>
						<b-nav-item>
							<router-link to="/konzept" class="animation">
								<span>{{ $t('home.navigation.links.second') }}</span>
							</router-link>
						</b-nav-item>
						<b-nav-item>
							<router-link to="/leistungen" class="animation">
								<span>{{ $t('home.navigation.links.third') }}</span>
							</router-link>
						</b-nav-item>
						<b-nav-item>
							<router-link to="/unternehmen" class="animation">
								<span>{{ $t('home.navigation.links.fourth') }}</span>
							</router-link>
						</b-nav-item>
						<b-nav-item>
							<router-link to="/partner" class="animation" data-hover="Partner Werden">
								<span>{{ $t('home.navigation.links.fifth') }}</span>
							</router-link>
						</b-nav-item>
						<b-nav-item class="contact-button">
							<router-link to="/contact">
								<b-button class="btn btn-black btn-medium">
									{{ $t('home.navigation.links.sixth') }}
								</b-button>
							</router-link>
						</b-nav-item>
					</b-navbar-nav>
				</b-collapse>
			</div>
		</b-navbar>
		<transition name="fade">
			<div>
				<router-view></router-view>
			</div>
		</transition>
		<Footer />
		<transition name="fade">
			<div v-if="showCookies" title="Cookies Hinweis" class="cookiesModal">
				<b-row>
					<p>
						Durch die Nutzung dieser Webseite erklären Sie sich mit der Verwendung von Cookies einverstanden.
						<router-link to="/datenschutz">Mehr erfahren</router-link>
					</p>
					<b-button size="sm" class="btn btn-white btn-medium float-right" @click="showCookies = false">
						Schließen
					</b-button>
				</b-row>
			</div>
		</transition>
	</div>
</template>

<script>
	import Footer from './components/Footer.vue';
	const logoImg = require('./assets/images/ImperiaLogo.png');
	const logoOnly = require('./assets/images/ImperiaLogoOnly.png');
	export default {
		name: 'App',
		metaInfo: {
			title: 'Immobilie als Kapitalanlage - Immobilien sofort verfügbar!',
		},
		components: { Footer },
		data: () => ({
			showCookies: true,
			logo: logoImg,
			logoOnly: logoOnly,
			scrollPosition: null,
			windowWidth: window.innerWidth,
		}),
		mounted() {
			window.addEventListener('scroll', this.updateScroll);
			window.onresize = () => {
				this.windowWidth = window.innerWidth;
			};
		},
		methods: {
			updateScroll() {
				this.scrollPosition = window.scrollY;
			},
			changeTheme(value) {
				this.activeTheme = value;
			},
		},
		destroy() {
			window.removeEventListener('scroll', this.updateScroll);
		},
	};
</script>

<style></style>
