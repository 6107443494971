import Vue from 'vue';
import App from './App.vue';
import router from './router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import './assets/styles/main.scss';
//Icons
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';

//Solid icons
import { faHeadset, faFileSignature, faHandHoldingUsd, faHandshake, faSearchDollar, faHome, faUserFriends, faUserTie, faHardHat, faUsers, faLongArrowAltDown } from '@fortawesome/free-solid-svg-icons';
library.add(faHeadset, faFileSignature, faHandHoldingUsd, faHandshake, faSearchDollar, faHome, faUserFriends, faUserTie, faHardHat, faUsers, faLongArrowAltDown);

//Axios
import axios from 'axios';
import VueAxios from 'vue-axios';
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

import VueRouter from 'vue-router';

import AOS from 'aos';
import 'aos/dist/aos.css';

// Languages
import VueI18n from 'vue-i18n';
import i18n from './i18n';
import VueMeta from 'vue-meta';
import LoadScript from 'vue-plugin-load-script';

var VueScrollTo = require('vue-scrollto');

Vue.use(VueScrollTo, {
	duration: 500,
	easing: 'ease',
	offset: -150,
	force: true,
	cancelable: true,
});
Vue.use(LoadScript);

Vue.use(VueMeta);
Vue.use(VueI18n);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(VueRouter);

Vue.use(VueAxios, axios);

Vue.config.productionTip = false;

new Vue({
	created() {
		AOS.init();
	},
	router,
	i18n,
	// store,
	render: h => h(App),
}).$mount('#app');
