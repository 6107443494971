import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

async function getLocales() {
	const languages = ['de'];
	let langData = {};
	for (const lang of languages) {
		const messages = await getMessage(lang).then(response => response);
		langData[lang] = messages;
	}
	async function getMessage(lang) {
		return await fetch(`https://www.imperiaimmobilien.de/${lang}.json`, {
			
		})
			.then(response => response.json())
			.then(data => data)

			.catch(e => console.log('Get content ERROR:', e));
	}
	return langData;
}
export async function loadLocaleMessages(lang) {
	const messages = await getLocales();
	i18n.setLocaleMessage(lang, messages[lang]);
}

const i18n = new VueI18n({
	locale: process.env.VUE_APP_I18N_LOCALE || 'de',
	fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'de',
});
export default i18n;
