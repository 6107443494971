<template>
	<div class="footer">
		<b-container>
			<b-row class="footer-infos" style="justify-content: center">
				<b-col sm="12" md="2" lg="3" xl="3" class="logo-footer">
					<!-- Display Name left from logi if smaller than 768x -->
					<img :src="logo" height="30" />
					<p v-if="windowWidth >= 768">Imperia Immobilien</p>
				</b-col>
				<b-col sm="12" md="10" lg="7" xl="6" class="contact-footer">
					<p v-if="windowWidth < 768">Imperia Immobilien</p>
					<div class="footer-item-wrapper">
						<p>Rudolf-Diesel Ring 32</p>
					</div>
					<div class="footer-item-wrapper">
						<p>82054 Sauerlach</p>
					</div>
					<div class="footer-item-wrapper last-mobile">
						<p>kontakt@imperiaimmobilien.de</p>
					</div>
					<div class="footer-item-wrapper" v-if="windowWidth > 768">
						<router-link to="/datenschutz"><p>Datenschutz</p></router-link>
					</div>
					<div v-if="windowWidth > 768">
						<router-link to="/impressum"><p>Impressum</p></router-link>
					</div>
				</b-col>
				<b-col xl="3" v-if="(windowWidth > 992 && windowWidth < 1005) || windowWidth > 1200" class="contact-footer">
					<p class="copyrights">© 2021 Imperia Immobilien. All Rights Reserved.</p>
				</b-col>
			</b-row>
			<b-row v-if="windowWidth < 768" class="footer-infos mobile">
				<b-col sm="12" class="contact-footer mobile-impressum">
					<router-link to="/datenschutz"><p>Datenschutz</p></router-link>

					<router-link to="/impressum">Impressum</router-link>
				</b-col>
			</b-row>
			<b-row v-if="windowWidth < 768" class="footer-infos mobile">
				<b-col sm="12" class="contact-footer mt-1 copyrights">
					<p>© 2021 Imperia Immobilien GmbH. All Rights Reserved.</p>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
	// import LocaleSwitcher from './LocaleSwitcher';
	const logoImg = require('../assets/images/ImperiaLogoOnly.png');
	export default {
		name: 'Footer',
		components: {},
		data: () => ({
			windowWidth: window.innerWidth,
			logo: logoImg,
		}),
		mounted() {
			window.onresize = () => {
				this.windowWidth = window.innerWidth;
			};
		},
	};
</script>
