import Vue from 'vue';
import Router from 'vue-router';
// import VueRouter from 'vue-router';

import Home from '../pages/Home.vue';
// import Impressum from '../pages/Impressum.vue';
// import About from './views/About.vue';
// import AboutMore from './views/AboutMore.vue';
import { loadLocaleMessages } from '../i18n';
import i18n from '../i18n';
Vue.use(Router);
const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,

	routes: [
		{
			path: '/',
			name: 'home',
			component: Home,
			meta: { title: 'Immobilie als Kapitalanlage - Immobilien sofort verfügbar!' },
		},
		{
			path: '/impressum',
			name: 'impressum',
			component: () => import(/* webpackChunkName: "format" */ '../pages/Impressum.vue'),
		},
		{
			path: '/datenschutz',
			name: 'datenschutz',
			component: () => import(/* webpackChunkName: "format" */ '../pages/Datenschutz.vue'),
		},
		{
			path: '/anlage',
			name: 'anlage',
			component: () => import(/* webpackChunkName: "format" */ '../pages/ImmobilieAnlage.vue'),
		},
		{
			path: '/konzept',
			name: 'konzept',
			component: () => import(/* webpackChunkName: "format" */ '../pages/Konzept.vue'),
		},
		{
			path: '/leistungen',
			name: 'leistungen',
			component: () => import(/* webpackChunkName: "format" */ '../pages/Leistungen.vue'),
		},
		{
			path: '/unternehmen',
			name: 'unternehmen',
			component: () => import(/* webpackChunkName: "format" */ '../pages/UnsereUnternehmen.vue'),
		},
		{
			path: '/partner',
			name: 'partner',
			component: () => import(/* webpackChunkName: "format" */ '../pages/Partner.vue'),
		},
		{
			path: '/contact',
			name: 'contact',
			component: () => import(/* webpackChunkName: "format" */ '../pages/Contact.vue'),
		},
	],
});
router.beforeEach(function(to, from, next) {
	setTimeout(() => {
		const lang = i18n.locale;
		loadLocaleMessages(lang).then(() => {
			next();
			window.scrollTo(0, 0);
		});
	}, 10);
});
router.onError(error => {
	if (/loading chunk \d* failed./i.test(error.message)) {
		window.location.reload();
	}
});
export default router;
